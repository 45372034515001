export const API_URLS = {
  classifiers: {
    index: '/classifiers',
  },
  subscription: {
    index: '/subscription',
    subscription: '/subscription/:subscriptionId',
    balance: '/subscription/:id/balance',
    cdr: '/subscription/:id/cdr',
  },
  sim: {
    activate: '/task/confirm-delivery',
    status: '/task',
    validate: '/sim/validate',
  },
  dealer: {
    register: '/dealer/register',
  },
  agent: {
    register: '/dealer/agent',
  },
  ticket: {
    submit: '/ticket/submit',
    list: '/ticket',
  },
  ticketCategory: {
    index: '/ticket-category',
  },
  offer: {
    product: '/offer/customer/product/:id',
    products: '/offer/public-product',
    productsForAddons: '/offer/offer/product/:offerId/:productId',
    productsForExistingCustomer: '/offer/customer/product',
    matchingOffers: '/offer/match',
  },
  inventory: {
    index: '/inventory/type/:inventoryTypeId',
    resendInventoryQRCode: '/inventory/deliver/:inventoryId',
  },
  invoice: {
    index: '/invoice',
    list: '/invoice/by-account',
    notify: '/invoice/notify',
    upcomingAmount: '/invoice/:accountId/upcoming-invoice-amount',
  },
  order: {
    list: '/order',
    submit: '/order/submit-order',
    order: '/order/:orderId',
    submitAddOn: '/order/submit-add-on',
    submitTopUp: '/order/submit-top-up',
    submitCustomerDataUpdate: '/order/submit-change-customer-data',
    createNewOrder: '/order',
    addNewOrderOffers: '/order/offers',
    addPortInDataToOrder: '/order/port-in',
    addPaymentDataToOrder: '/order/payment',
    updatePaymentData: '/order/:orderId/update-payment',
    addNewOrderResources: '/order/:orderId/resources',
    removeOrderResources: '/order/:orderId/remove-resources',
    changeInventoryOrder: '/order/CHANGE_INVENTORY',
    setSubscriptionForModificationAndProductsToBeTerminated:
      '/order/:orderId/modify-subscription',
    addProductFieldValues: '/order/:orderId/product-fields',
  },
  orderAuthless: {
    order: '/order-authless/:orderId',
    submitTopUp: '/order-authless/submit-top-up',
    submit: '/order-authless/submit-order',
    createNewOrder: '/order-authless',
    addNewOrderOffers: '/order-authless/offers',
    addPortInDataToOrder: '/order-authless/port-in',
    addNewCustomerToOrder: '/order-authless/customer',
    addNewCustomerToOrderNoFiles: '/order-authless/customer-no-files',
    addPaymentDataToOrder: '/order-authless/payment',
    updatePaymentData: '/order-authless/:tenantId/:orderId/update-payment',
    addNewOrderResources: '/order-authless/:orderId/resources',
    removeOrderResources: '/order-authless/:tenantId/:orderId/remove-resources',
    sendVerificationCodeByEmail:
      '/order-authless/:tenantId/:orderId/verification-code',
    validateVerificationCode:
      '/order-authless/:tenantId/:orderId/:verificationCode/validate-verification-code',
    addProductFieldValues: '/order-authless/:tenantId/:orderId/product-fields',
  },
  customer: {
    index: '/customer',
    register: '/customer/register',
    payments: '/payment/:accountId',
  },
  auth: {
    forgotPassword: '/auth/forgot-password',
    sendVerificationCodeToMsisdn: '/auth/verification-code',
    creteSubscriptionUser: '/auth/subscription-user',
    changeSubscriptionUserPassword: '/auth/change-password',
  },
  user: {
    index: '/user',
  },
  tenant: {
    getTenantById: '/tenant-authless',
    getTenantPaymentCapabilitiesForUnauthenticatedUser:
      '/tenant-payment-capabilities-authless/:tenantId',
    getTenantPaymentCapabilities: '/tenant-payment-capabilities',
  },
  payment: {
    capturePayPalPayment: 'paypal/:orderId/capture',
  },
  paymentAuthless: {
    capturePayPalPayment: 'paypal-authless/:tenantId/:orderId/capture',
  },
} as const;
