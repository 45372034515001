import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const WORLD_MOBILE_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: 'WORLD_MOBILE',
        staging: undefined,
      },
      name: 'world-mobile',
      cmsSubdomain: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        qa: undefined,
        prod: 'world-mobile-production',
      },
      ryftPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      stripePublicKey: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        prod: 'pk_live_51Orq9kEA5EBQh7xNfKAaFfFTwrvhhofGdcIAN1quhVjifDHWVI3h90ivwAiN0vnIHDqu6KlgbPCZ8afFQASLDpaV00wEuOWrbq',
        qa: undefined,
      },
      paypalPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: 'AQTJEEEAyLqXyEkBDauEX1lefifnB79jLlfSa55ea3ya7YiFXxatJ3OpCGBep2s7N81L6smwrZPaF0gB',
        staging: undefined,
      },
      authTenantId: {
        local: undefined,
        demo: undefined,
        staging: undefined,
        qa: undefined,
        prod: 'wn9mdp5n',
      },
      ocbDigitalBaseUrl: {
        demo: undefined,
        qa: undefined,
        prod: 'https://mobile.worldmobile.us/digital/api',
        staging: undefined,
      },
      blueBoxChatBotUrl: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: undefined,
      },
      googleTagManagerId: {
        demo: undefined,
        qa: undefined,
        staging: undefined,
        prod: 'G-C27L3KPGQQ',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {
    // Typography styles from Figma
    // typographyStyles: {
    //   h1: createHeadingStyle(96, 60, 40, 1, fontWeightSizes.fontWeightBold),
    //   h2: createHeadingStyle(64, 48, 36, 1, fontWeightSizes.fontWeightBold),
    //   h3: createHeadingStyle(40, 28, 20, 1, fontWeightSizes.fontWeightBold),
    //   h4: createHeadingStyle(36, 24, 18, 1.1, fontWeightSizes.fontWeightBold),
    //   h5: createHeadingStyle(24, 20, 16, 1.1, fontWeightSizes.fontWeightBold),
    //   h6: createHeadingStyle(20, 18, 16, 1.1, fontWeightSizes.fontWeightBold),
    //   paragraph: createTextStyle(18, 16, 14, 1.5),
    // },
  },
  seo: {
    displayName: 'World Mobile',
  },
};
